import { Component, Vue } from "vue-property-decorator";

import { SideNav } from "@/layout";
import { Lightbox } from "@/components";
import { Common } from "../Common";
import { InstructionsModel } from "@/models";

type response = { [key: string]: unknown };

@Component<AdminInstructionsIndexController>({
  components: {
    SideNav, Lightbox
  }
})
export default class AdminInstructionsIndexController extends Vue {
  private instructionsList: object = {};
  private token = window.localStorage.getItem('adminToken') as string;

  public async created() {
    const item: { [key: string]: string } = {
      token: this.token,
    }
    const data = await InstructionsModel.getList(item) as response;
    Common.checkToken(data.ERR_CODE as number);
    this.instructionsList = data.list as object;
  }
}
